import React, { useContext } from 'react';
import ThemeContext from '../themes/ThemeContext';

interface TitleProps {
  className?: string;
}

const Title: React.FC<TitleProps> = ({ className }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <svg
      className={className}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 332 228'
    >
      <g>
        <path
          fill={theme.primary}
          d='M100.4,138.2c-1.1-0.6-2.4-0.9-3.8-0.9c-1.4,0-2.7,0.3-3.8,0.9c-1.1,0.6-2,1.4-2.6,2.5c-0.6,1-1,2.2-1,3.5
		c0,1.3,0.3,2.5,1,3.6c0.7,1,1.5,1.9,2.6,2.5c1.1,0.6,2.4,0.9,3.8,0.9c1.4,0,2.7-0.3,3.8-0.9c1.1-0.6,2-1.4,2.6-2.5
		c0.7-1.1,1-2.3,1-3.6c0-1.3-0.3-2.5-1-3.5C102.3,139.6,101.5,138.8,100.4,138.2z M101,146.8c-0.4,0.7-1,1.3-1.8,1.8
		c-0.7,0.4-1.6,0.6-2.6,0.6c-1,0-1.8-0.2-2.6-0.6c-0.8-0.4-1.4-1-1.8-1.8c-0.4-0.8-0.6-1.6-0.6-2.5c0-1,0.2-1.8,0.6-2.6
		c0.4-0.7,1-1.3,1.8-1.7c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.8,0.2,2.6,0.6c0.8,0.4,1.4,1,1.8,1.8c0.4,0.7,0.7,1.6,0.7,2.5
		C101.6,145.2,101.4,146,101,146.8z'
        />
        <path
          fill={theme.primary}
          d='M5.8,139.9c0.8-0.4,1.7-0.6,2.6-0.6c0.7,0,1.4,0.1,2.1,0.4c0.6,0.3,1.2,0.7,1.7,1.2l1.5-1.5
		c-0.6-0.7-1.4-1.2-2.3-1.6c-0.9-0.3-1.9-0.5-3.1-0.5c-1.4,0-2.7,0.3-3.8,0.9c-1.1,0.6-2,1.4-2.6,2.5c-0.6,1-1,2.2-1,3.6
		c0,1.3,0.3,2.5,1,3.6c0.6,1,1.5,1.9,2.6,2.5c1.1,0.6,2.4,0.9,3.8,0.9c1.1,0,2.2-0.2,3-0.5c0.9-0.4,1.7-0.9,2.3-1.6l-1.5-1.5
		c-0.5,0.6-1.1,1-1.7,1.2c-0.6,0.3-1.3,0.4-2.1,0.4c-1,0-1.8-0.2-2.6-0.6c-0.8-0.4-1.4-1-1.8-1.8c-0.4-0.8-0.6-1.6-0.6-2.5
		c0-1,0.2-1.8,0.6-2.6C4.5,140.9,5.1,140.3,5.8,139.9z'
        />
        <path
          fill={theme.primary}
          d='M78.5,138c-0.9-0.4-1.9-0.6-3.1-0.6h-5.5V151h2.3v-4h3.2c1.2,0,2.2-0.2,3.1-0.6c0.9-0.4,1.5-0.9,2-1.6
		c0.5-0.7,0.7-1.6,0.7-2.6c0-1-0.2-1.9-0.7-2.6C80.1,138.9,79.4,138.4,78.5,138z M78,144.4c-0.6,0.5-1.5,0.7-2.7,0.7h-3.1v-5.7h3.1
		c1.2,0,2.1,0.2,2.7,0.7c0.6,0.5,0.9,1.2,0.9,2.1C78.9,143.2,78.6,143.9,78,144.4z'
        />
        <path
          fill={theme.primary}
          d='M32.1,138.2c-1.1-0.6-2.4-0.9-3.8-0.9c-1.4,0-2.7,0.3-3.8,0.9c-1.1,0.6-2,1.4-2.6,2.5c-0.6,1-1,2.2-1,3.5
		c0,1.3,0.3,2.5,1,3.6c0.7,1,1.5,1.9,2.6,2.5c1.1,0.6,2.4,0.9,3.8,0.9c1.4,0,2.7-0.3,3.8-0.9c1.1-0.6,2-1.4,2.6-2.5
		c0.7-1.1,1-2.3,1-3.6c0-1.3-0.3-2.5-1-3.5C34,139.6,33.2,138.8,32.1,138.2z M32.7,146.8c-0.4,0.7-1,1.3-1.8,1.8
		c-0.7,0.4-1.6,0.6-2.6,0.6c-1,0-1.8-0.2-2.6-0.6c-0.8-0.4-1.4-1-1.8-1.8c-0.4-0.8-0.6-1.6-0.6-2.5c0-1,0.2-1.8,0.6-2.6
		c0.4-0.7,1-1.3,1.8-1.7c0.8-0.4,1.6-0.6,2.6-0.6c1,0,1.8,0.2,2.6,0.6c0.8,0.4,1.4,1,1.8,1.8c0.4,0.7,0.7,1.6,0.7,2.5
		C33.3,145.2,33.1,146,32.7,146.8z'
        />
        <polygon
          fill={theme.primary}
          points='122.8,147.1 114.9,137.5 113,137.5 113,151 115.3,151 115.3,141.4 123.2,151 125.1,151 125.1,137.5 
		122.8,137.5 	'
        />
        <polygon
          fill={theme.primary}
          points='57.6,137.5 52.2,146.6 46.7,137.5 44.7,137.5 44.7,151 46.9,151 46.9,141.8 51.6,149.4 52.7,149.4 
		57.4,141.6 57.4,151 59.6,151 59.6,137.5 	'
        />
        <polygon
          fill={theme.primary}
          points='164.4,147.1 156.6,137.5 154.7,137.5 154.7,151 157,151 157,141.4 164.8,151 166.7,151 166.7,137.5 
		164.4,137.5 	'
        />
        <path
          fill={theme.primary}
          d='M307.8,146.4c0.9-0.4,1.5-0.9,2-1.6c0.5-0.7,0.7-1.6,0.7-2.5c0-1-0.2-1.9-0.7-2.6c-0.5-0.7-1.1-1.3-2-1.6
		c-0.9-0.4-1.9-0.6-3.1-0.6h-5.5V151h2.3v-4h3.2c0.2,0,0.4,0,0.6,0l2.9,4.1h2.5l-3.2-4.5C307.6,146.5,307.7,146.5,307.8,146.4z
		 M304.6,145.1h-3.1v-5.7h3.1c1.2,0,2.1,0.2,2.7,0.7c0.6,0.5,0.9,1.2,0.9,2.1c0,0.9-0.3,1.6-0.9,2.1
		C306.7,144.8,305.8,145.1,304.6,145.1z'
        />
        <path
          fill={theme.primary}
          d='M266.7,146.4c0.9-0.4,1.5-0.9,2-1.6c0.5-0.7,0.7-1.6,0.7-2.5c0-1-0.2-1.9-0.7-2.6c-0.5-0.7-1.1-1.3-2-1.6
		c-0.9-0.4-1.9-0.6-3.1-0.6h-5.5V151h2.3v-4h3.2c0.2,0,0.4,0,0.6,0l2.9,4.1h2.5l-3.2-4.5C266.5,146.5,266.6,146.5,266.7,146.4z
		 M263.5,145.1h-3.1v-5.7h3.1c1.2,0,2.1,0.2,2.7,0.7c0.6,0.5,0.9,1.2,0.9,2.1c0,0.9-0.3,1.6-0.9,2.1
		C265.6,144.8,264.7,145.1,263.5,145.1z'
        />
        <path
          fill={theme.primary}
          d='M282.7,137.5l-6.2,13.5h2.3l1.4-3.2h7.3l1.4,3.2h2.3l-6.2-13.5H282.7z M281,146l2.8-6.4l2.8,6.4H281z'
        />
        <polygon
          fill={theme.primary}
          points='330.9,137.5 328.5,137.5 324.2,144.4 319.9,137.5 317.4,137.5 323,146.5 323,151 325.3,151 
		325.3,146.6 	'
        />
        <polygon
          fill={theme.primary}
          points='137.7,145.1 144.5,145.1 144.5,143.2 137.7,143.2 137.7,139.4 145.3,139.4 145.3,137.5 135.4,137.5 
		135.4,151 145.6,151 145.6,149.1 137.7,149.1 	'
        />
        <polygon
          fill={theme.primary}
          points='174.9,139.4 179.5,139.4 179.5,151 181.8,151 181.8,139.4 186.4,139.4 186.4,137.5 174.9,137.5 	'
        />
        <polygon
          fill={theme.primary}
          points='209,137.5 206.7,137.5 206.7,151 216.2,151 216.2,149.1 209,149.1 	'
        />
        <rect
          x='224.5'
          y='137.5'
          fill={theme.primary}
          width='2.3'
          height='13.5'
        />
        <path
          fill={theme.primary}
          d='M246.5,144c0.3-0.1,0.5-0.3,0.8-0.5c0.7-0.6,1.1-1.4,1.1-2.5c0-1.1-0.4-1.9-1.3-2.5c-0.9-0.6-2.1-1-3.7-1h-6.3
		V151h6.7c1.8,0,3.1-0.3,4-1c0.9-0.7,1.4-1.5,1.4-2.7c0-1.2-0.4-2.1-1.1-2.7C247.6,144.3,247.1,144.1,246.5,144z M243.1,139.2
		c1,0,1.7,0.2,2.2,0.5c0.5,0.3,0.8,0.8,0.8,1.5s-0.3,1.2-0.8,1.5s-1.2,0.5-2.2,0.5h-3.7v-4H243.1z M246,148.7
		c-0.5,0.3-1.3,0.5-2.3,0.5h-4.2V145h4.2c1,0,1.8,0.2,2.3,0.5c0.5,0.3,0.8,0.9,0.8,1.6C246.8,147.9,246.5,148.4,246,148.7z'
        />
      </g>
      <g>
        <path
          fill={theme.secondary}
          d='M25.5,197.7c-1.3-0.7-2.7-1.3-4.3-1.7c-1.5-0.4-3.1-0.8-4.6-1.2c-1.5-0.4-3-0.8-4.3-1.2
		c-1.3-0.4-2.3-1-3.1-1.8C8.4,191,8,190,8,188.7c0-1.1,0.3-2,0.9-2.9c0.6-0.9,1.6-1.5,2.9-2.1c1.3-0.5,3-0.8,5-0.8
		c1.6,0,3.3,0.2,5,0.7c1.7,0.4,3.3,1.2,4.9,2.2l1.7-4.1c-1.5-1-3.3-1.8-5.3-2.4c-2.1-0.5-4.1-0.8-6.2-0.8c-3.1,0-5.8,0.5-7.8,1.4
		c-2,0.9-3.5,2.2-4.5,3.7c-1,1.5-1.4,3.2-1.4,5.1c0,1.9,0.4,3.5,1.2,4.7c0.8,1.2,1.8,2.2,3.1,2.9c1.3,0.7,2.7,1.3,4.3,1.8
		c1.6,0.4,3.1,0.8,4.7,1.2c1.5,0.3,2.9,0.8,4.2,1.2c1.3,0.4,2.3,1,3.1,1.8c0.8,0.8,1.2,1.7,1.2,3c0,1.1-0.3,2-0.9,2.9
		c-0.6,0.8-1.6,1.5-2.9,2c-1.3,0.5-3,0.7-5.1,0.7c-2.3,0-4.5-0.4-6.6-1.2s-3.9-1.8-5.2-3l-1.9,4c1.4,1.3,3.4,2.4,5.9,3.3
		c2.5,0.8,5.1,1.2,7.9,1.2c3.1,0,5.8-0.5,7.8-1.4c2.1-1,3.6-2.2,4.6-3.7c1-1.6,1.5-3.3,1.5-5.1c0-1.9-0.4-3.4-1.2-4.6
		C27.8,199.4,26.8,198.4,25.5,197.7z'
        />
        <path
          fill={theme.secondary}
          d='M61.9,180.5c-2.2-1-4.9-1.5-8.1-1.5h-14V215H45v-10.9h8.9c3.1,0,5.8-0.5,8.1-1.5c2.2-1,3.9-2.5,5.1-4.3
		c1.2-1.9,1.8-4.1,1.8-6.7c0-2.6-0.6-4.8-1.8-6.7C65.9,183,64.2,181.5,61.9,180.5z M61.2,197.5c-1.7,1.4-4.2,2.1-7.4,2.1H45v-16.1
		h8.7c3.3,0,5.8,0.7,7.4,2.1c1.7,1.4,2.6,3.4,2.6,6S62.9,196.1,61.2,197.5z'
        />
        <rect
          x='79'
          y='179.1'
          fill={theme.secondary}
          width='5.1'
          height='35.9'
        />
        <path
          fill={theme.secondary}
          d='M122.7,181.3c-2.9-1.5-6.3-2.3-10.1-2.3H97.4V215h15.1c3.8,0,7.2-0.8,10.1-2.3c2.9-1.5,5.2-3.6,6.8-6.3
		c1.6-2.7,2.5-5.8,2.5-9.4s-0.8-6.7-2.5-9.4C127.9,184.9,125.6,182.8,122.7,181.3z M125.1,204.1c-1.2,2-2.9,3.6-5.1,4.7
		c-2.2,1.1-4.7,1.7-7.7,1.7h-9.7v-27h9.7c3,0,5.5,0.6,7.7,1.7c2.2,1.1,3.9,2.7,5.1,4.7s1.8,4.4,1.8,7.1
		C126.9,199.7,126.3,202.1,125.1,204.1z'
        />
        <path
          fill={theme.secondary}
          d='M328.4,200.6c-0.8-1.2-1.8-2.2-3.1-2.9c-1.3-0.7-2.7-1.3-4.3-1.7c-1.5-0.4-3.1-0.8-4.6-1.2
		c-1.5-0.4-3-0.8-4.3-1.2c-1.3-0.4-2.3-1-3.1-1.8c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.3-2,0.9-2.9c0.6-0.9,1.6-1.5,2.9-2.1
		c1.3-0.5,3-0.8,5-0.8c1.6,0,3.3,0.2,5,0.7c1.7,0.4,3.3,1.2,4.9,2.2l1.7-4.1c-1.5-1-3.3-1.8-5.3-2.4c-2.1-0.5-4.1-0.8-6.2-0.8
		c-3.2,0-5.8,0.5-7.8,1.4c-2,0.9-3.5,2.2-4.5,3.7c-1,1.5-1.4,3.2-1.4,5.1c0,1.9,0.4,3.5,1.2,4.7c0.8,1.2,1.8,2.2,3.1,2.9
		c1.3,0.7,2.7,1.3,4.3,1.8c1.6,0.4,3.1,0.8,4.7,1.2c1.5,0.3,2.9,0.8,4.2,1.2c1.3,0.4,2.3,1,3.1,1.8c0.8,0.8,1.2,1.7,1.2,3
		c0,1.1-0.3,2-0.9,2.9c-0.6,0.8-1.6,1.5-2.9,2c-1.3,0.5-3,0.7-5.1,0.7c-2.3,0-4.5-0.4-6.6-1.2c-2.1-0.8-3.9-1.8-5.2-3l-1.9,4
		c1.4,1.3,3.4,2.4,5.9,3.3c2.5,0.8,5.1,1.2,7.9,1.2c3.1,0,5.8-0.5,7.8-1.4c2.1-1,3.6-2.2,4.6-3.7c1-1.6,1.5-3.3,1.5-5.1
		C329.6,203.3,329.2,201.8,328.4,200.6z'
        />
        <path
          fill={theme.secondary}
          d='M290.8,197.7c-1.3-0.7-2.7-1.3-4.3-1.7c-1.5-0.4-3.1-0.8-4.6-1.2c-1.5-0.4-3-0.8-4.3-1.2
		c-1.3-0.4-2.3-1-3.1-1.8c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.3-2,0.9-2.9c0.6-0.9,1.6-1.5,2.9-2.1c1.3-0.5,3-0.8,5-0.8
		c1.6,0,3.3,0.2,5,0.7c1.7,0.4,3.3,1.2,4.9,2.2l1.7-4.1c-1.5-1-3.3-1.8-5.3-2.4c-2.1-0.5-4.1-0.8-6.2-0.8c-3.1,0-5.8,0.5-7.8,1.4
		c-2,0.9-3.5,2.2-4.5,3.7c-1,1.5-1.4,3.2-1.4,5.1c0,1.9,0.4,3.5,1.2,4.7c0.8,1.2,1.8,2.2,3.1,2.9c1.3,0.7,2.7,1.3,4.3,1.8
		c1.6,0.4,3.1,0.8,4.7,1.2c1.5,0.3,2.9,0.8,4.2,1.2c1.3,0.4,2.3,1,3.1,1.8c0.8,0.8,1.2,1.7,1.2,3c0,1.1-0.3,2-0.9,2.9
		c-0.6,0.8-1.6,1.5-2.9,2c-1.3,0.5-3,0.7-5.1,0.7c-2.3,0-4.5-0.4-6.6-1.2c-2.1-0.8-3.9-1.8-5.2-3l-1.9,4c1.4,1.3,3.4,2.4,5.9,3.3
		c2.5,0.8,5.1,1.2,7.9,1.2c3.1,0,5.8-0.5,7.8-1.4c2.1-1,3.6-2.2,4.6-3.7c1-1.6,1.5-3.3,1.5-5.1c0-1.9-0.4-3.4-1.2-4.6
		C293.2,199.4,292.1,198.4,290.8,197.7z'
        />
        <polygon
          fill={theme.secondary}
          points='147.6,198.9 165.6,198.9 165.6,194.6 147.6,194.6 147.6,183.5 167.8,183.5 167.8,179.1 142.4,179.1 
		142.4,215 168.5,215 168.5,210.5 147.6,210.5 	'
        />
        <polygon
          fill={theme.secondary}
          points='201.9,179.1 190.4,198 178.9,179.1 173.4,179.1 187.7,202.5 187.7,215 192.7,215 192.7,202.6 
		207,179.1 	'
        />
        <path
          fill={theme.secondary}
          d='M237.4,187.1c1.3-1.2,2.8-2.2,4.5-2.9c1.7-0.7,3.6-1,5.6-1c2,0,3.8,0.4,5.5,1.1c1.7,0.7,3.3,1.7,4.6,3.2
		l3.3-3.2c-1.6-1.8-3.6-3.2-6-4.2c-2.3-1-4.9-1.4-7.7-1.4c-2.7,0-5.3,0.5-7.6,1.4c-2.3,0.9-4.3,2.2-6,3.9c-1.7,1.6-3,3.6-4,5.8
		c-1,2.2-1.4,4.7-1.4,7.3s0.5,5.1,1.4,7.3c1,2.2,2.3,4.2,4,5.9c1.7,1.6,3.7,2.9,6,3.9c2.3,0.9,4.9,1.3,7.6,1.3
		c2.8,0,5.4-0.5,7.7-1.4c2.4-1,4.4-2.4,6-4.2l-3.3-3.2c-1.4,1.5-2.9,2.6-4.6,3.3c-1.7,0.7-3.5,1-5.5,1c-2,0-3.9-0.3-5.6-1
		c-1.7-0.7-3.2-1.6-4.5-2.9c-1.3-1.2-2.3-2.7-3-4.4c-0.7-1.7-1-3.6-1-5.5c0-2,0.3-3.8,1-5.5C235.1,189.8,236.1,188.4,237.4,187.1z'
        />
      </g>
      <g>
        <path
          fill={theme.secondary}
          d='M185.7,57.3c2.6-3.4,4-8.1,3.8-12.5c-0.2-3.4-1-6.3-3.6-5.3c-4.6,1.8-11.7,8.5-14,10.9c-0.4,0.6-0.4,1.6,0,2
		c2.4,2.4,8.3,5,10.5,5.9C183.6,58.8,184.8,58.4,185.7,57.3z'
        />
        <path
          fill={theme.secondary}
          d='M160.8,50.7c-2.4-2.6-9.1-9.1-14-10.9c-3-1.2-3.6,1.6-3.6,5.3c-0.2,4.4,1.4,9.1,3.8,12.5c0.8,1,2,1.4,3.3,1
		c2.2-0.8,8.3-3.6,10.5-5.9C161.2,52.1,161.2,51.1,160.8,50.7z'
        />
        <path
          fill={theme.primary}
          d='M129.1,45l33.9-33.9c0.8-0.8,1.4-2,1.4-3.4V1.2c0-1-1.2-1.6-2-0.8l-44.1,44.1c-0.8,0.8-0.2,2,0.8,2h6.4
		C127.1,46.4,128.3,46,129.1,45z'
        />
        <path
          fill={theme.primary}
          d='M202.9,45.1L169,11.1c-1-0.8-1.4-2-1.5-3.4V1.2c0-1,1.2-1.6,2-0.8l44.1,44c0.8,0.8,0.2,2-0.8,2h-6.4
		C204.9,46.5,203.7,45.9,202.9,45.1z'
        />
        <path
          fill={theme.primary}
          d='M129.1,50.9L163,84.9c1,0.8,1.4,2,1.5,3.5v6.4c0,1-1.2,1.6-2,0.8l-44.1-44.1c-0.8-0.8-0.2-2,0.8-2h6.5
		C127.1,49.5,128.3,50.1,129.1,50.9z'
        />
        <path
          fill={theme.primary}
          d='M168.9,84.9L202.9,51c0.8-1,2-1.4,3.5-1.5h6.4c1,0,1.6,1.2,0.8,2l-44.1,44.1c-0.8,0.8-2,0.2-2-0.8v-6.4
		C167.5,86.9,168.1,85.7,168.9,84.9z'
        />
      </g>
    </svg>
  );
};

export default Title;
